#folders {
    padding: 20px 30px;

    .folderIcon {
        color: #53acff;
        font-size: 70px;
    }

    .folderContainer {
        max-height: 150px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }

    .folderContainer:hover {
        background-color: #00000020;
    }
}

.deflex{
    display: flex;
    align-items: center;
}
