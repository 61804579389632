.hide {
  visibility: hidden;
  margin: 5px
}

.myDIV:hover+.hide {
  display: inline;
  visibility: visible;
  color: red;
  max-width: 80px;
}

.preview {
  display: flex;
  justify-content: space-around;

}

.size {
  width: 100%;
  height: 100px;
}

.elipText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}

.finanicalYears{
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: space-between;
}
